import LoginModel from '@/views/login/model/LoginModel'
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import { encryptWithRSA } from '@lemontree-ai/lemontree-admin-common-front/utils/RsaUtils';
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import LocalStorageManager from '@/storages/LocalStorageManager'
import router from '@/router/';
import routerPath from '@/router/routerPath';

export default class LoginViewModel {
  constructor() {
    this.model = new LoginModel();
    this.valid = {
      email:{
        value: 'email',
        valueType:'email',
        isValid:false,
        validAlertDesc: 'E-mail 양식을 확인해주세요',
      },
      password:{
        value: 'password_input',
        valueType:'password',
        isValid:false,
        validAlertDesc: '대문자, 소문자, 특수문자, 숫자를 조합하여 8자이상 입력해주세요',
      },
    };
    this.validStep2 = {
      mfa:{
        value: 'mfa',
        valueType:'mfa',
        isValid:false,
        validAlertDesc: '정확한 MFA 코드를 입력해주세요',
      },
    };
    this.isOneStepDone = false;
  }
  resetLoginInfo(){
    this.model = new LoginModel();
    this.isOneStepDone = false;
  }
  loginValidatedResult(){
    if(!this.isOneStepDone){
      return pageValidated(this.valid, this.model)
    }else{
      return pageValidated(this.validStep2, this.model)
    }
  }
  onClickLogin(){
    if(!this.isOneStepDone){
      this.getPublicKey()
    }else{
      this.postMfaLogin()
    }
  }

  getPublicKey(){
    const path = `${apiPath.PUBLIC_KEY}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.key = resultData.key;
      this.setPassword();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  setPassword(){
    let key = `-----BEGIN PUBLIC KEY-----${this.model.key}-----END PUBLIC KEY-----`;
    const encryptedDataResult = encryptWithRSA(key,this.model.password_input)
    this.model.password = encryptedDataResult;
    this.postLogin();
  }

  postLogin(){
    const path = `${apiPath.LOGIN}`;
    const data = this.model.getData();
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      LocalStorageManager.shared.setToken(resultData.token);
      store.dispatch('commonUserData/fetchUserData', resultData.user);
      if(resultData.user.userStatus === 'NEED_PASSWORD_CHANGE' && !store.getters['passowrdChangeError/getIsPasswordChangeError']){
        store.dispatch('commonToast/fetchToastStart', '비밀번호를 변경해주세요');
        store.dispatch('passowrdChangeError/fetchPasswordChangeErrorStart');
        return;
      }
      if(resultData.mfa){
        this.isOneStepDone = true;
      }else{
        router.push({ name: 'HOME' }).catch(()=>{});
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  postMfaLogin(){
    const path = `${apiPath.MAF_LOGIN}`;
    const data = this.model.getMfaData();
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      LocalStorageManager.shared.setToken(resultData.token);
      store.dispatch('commonUserData/fetchUserData', resultData.user);
      router.push({ name: 'HOME' }).catch(()=>{});
    this.isOneStepDone = true;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getMe(){
    const path = `${apiPath.ME}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}